<template>
  <div id="order-models">
    <!-- Content -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Veículos - Ordernar</h4>
          </CCardHeader>

          <CCardBody>
            <!-- Sortable -->
            <CRow>
              <label class="filterText">Filtrar pelo modelo</label>
              <CCol md="4" lg="4" xl="3">
                <div class="form-group">
                  <Multiselect
                    :value="
                      modelsOptions.find(({ value }) => value === query.model)
                    "
                    :options="modelsOptions"
                    @select="(selected) => (query.model = selected.value)"
                  />
                </div>
              </CCol>
              <CCol col="12">
                <Sortable
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-switch-status="false"
                  :enable-export="false"
                  @endDragging="endDragging"
                >
                </Sortable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Sortable from '@/components/ui/Sortable'
import { orderBy, debounce } from 'lodash'
import VehicleService from '@/services/vehicles.service'
import Multiselect from '@/components/ui/Multiselect'
import ModelService from '@/services/models.service'

export default {
  metaInfo: {
    title: 'Veículos',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_vehicles')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Sortable,
    Multiselect
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'status',
            label: 'Status',
            _style: 'width: 5rem;',
            sorter: false
          },
          {
            key: 'title',
            label: 'Título',
            sorter: false
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: false,
        model: '',
        sorter: {
          asc: true,
          column: 'order'
        },
        status: true
      },
      processing: false,
      modelsOptions: [],
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.getModels()
    await this.updateDataTable()
  },

  methods: {
    async getModels() {
      const query = {
        pagination: false,
        sorter: { asc: true, column: 'title' }
      }

      const { data, modelId } = await ModelService.index(query)
      const options = data.map(({ id, title }) => ({ value: id, label: title }))
      this.optionsToSearchVehicles = options
      this.modelsOptions = [...options]
      this.query.model = modelId
    },

    async updateDataTable() {
      const response = await VehicleService.index(this.query)

      if (response.data) {
        const newItems = response.data.map((item) => ({ ...item }))

        this.datatable.items = orderBy(newItems, ['order'])
      }
    },

    endDragging: debounce(function (items) {
      this.datatable.items = items
      VehicleService.updateOrders(this.datatable.items)

      this.$notify({
        group: 'notifications',
        type: 'success',
        text: 'Ordenação alterada com sucesso.'
      })
    }, 1000)
  }
}
</script>

<style lang="scss">
#order-models {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }

  .filterText {
    font-size: 14px;
    padding-top: 12px;
    padding-left: 16px;
  }
}
</style>
